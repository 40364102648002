import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { GatsbyBrowser, Script } from "gatsby";

const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ( { element, props } ) => {


    return (
        <>
            {/*
            { process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY &&
                <Script data-script={ 'recaptcha' } key={ "recaptcha-script" } data-src={ `https://www.google.com/recaptcha/api.js?render=${ process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY }` }/>
            }
            */}
            <AnimatePresence exitBeforeEnter>
                <div key={ props.location.pathname }>
                    { element }
                </div>
            </AnimatePresence>
            {/*
            { !allowCookies &&
                <div className={ "cookie-banner" }>
                    <p>We are using cookies to enhance the experience of this website and protect our inbox from spam.</p>
                    <button onClick={ () => setAllowCookies( true ) } className={ "button" }>Allow</button>
                </div>
            }
            */}
        </>
    )
}
export default wrapPageElement
